import { ReactComponentElement } from 'react'
import { IAxiosError } from '@/services'
import { ITracking } from '@/utils/piwik'

export enum ETheme {
  light,
  dark
}

export enum EModalType {
  limit,
  report
}

export enum EUploadType {
  email,
  link
}

export interface IForm {
  type: 'text' | 'email' | 'password'
  placeholder: string
  validation?: object
  errorMessage?: string
  name: string
}

export interface IPageState {
  component: ReactComponentElement<any>
  button?: IButton
}

export interface IButton {
  label: string
  onClick: () => void
  isDisable?: boolean
  isLoading?: boolean
  tracking?: ITracking
}

export interface IError {
  name: string
  displayMessage?: string
  error?: IAxiosError
  params?: Record<string, string>
}

export enum ETagType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  SPAN = 'span',
  SUP = 'sup',
  P = 'p',
  FRAGMENT = ''
}

export enum ETextColor {
  RED = 'text-red',
  DARK = 'text-gray-900',
  DEFAULT = 'text-gray-600'
}

export interface ISectionInfo {
  id?: string
  logo?: IImage
  title?: string
  subtitle?: string
  description?: string
  background?: string
}

export interface IImage {
  src: string
  alt: string
  title?: string
}

export enum EAppVersion {
  SUBSCRIBER,
  All
}

export enum ESubscription {
  NONE = 'None',
  ALLOW_LIST = 'Allow List',
  FREEBOX = 'Free SAS',
  FREE_MOBILE = 'Free Mobile SAS',
  FREE_PRO = 'Jaguar Network SAS'
}
