import { AppContext, AppContextType, defaultFormData } from '@/shared/contexts/AppContext'
import { useEffect, useMemo, useState } from 'react'
import { ESubscription, IError } from '@/shared/types'
import { captureAPMError } from '@/utils/elasticAPM'
import { DropzoneFile } from '@/components/templates/Upload/Steps/UploadItems'
import { TransferDataType } from '@/shared/types/transfer'
import { EUploadStep, UploadFormDataType } from '@/shared/types/upload'
import { useRouter } from 'next/router'

const ContextProvider = ({ children }) => {
  const [isSubscriber, setIsSubscriber] = useState<boolean>(false)
  const [subscription, setSubscription] = useState<ESubscription>(ESubscription.NONE)
  const [error, setError] = useState<IError>()
  const [uploadingFiles, setUploadingFiles] = useState<DropzoneFile[]>([])
  const [formData, setFormData] = useState<UploadFormDataType>(defaultFormData)
  const [password, setPassword] = useState<string>()
  const [transferData, setTransferData] = useState<TransferDataType>()
  const [currentStep, setCurrentStep] = useState<EUploadStep>(EUploadStep.MAIN)

  const router = useRouter()

  useEffect(() => {
    setError(undefined)
  }, [])

  const setGlobalError = (data?: IError) => {
    if (data) {
      const { name, error, params } = data

      const formattedParams = params
        ? Object.entries(params).reduce((acc, param) => {
            const [key, value] = param
            return `${acc} ${key} = ${value}, `
          }, `${error} error | `)
        : error

      const responseDetails = error?.response
        ? `error ${error?.response?.status} - ${error?.response?.data}`
        : 'error unknown'
      const apmError = new Error(`${responseDetails} - ${JSON.stringify(formattedParams)}`)
      apmError.name = name

      captureAPMError(apmError)

      setFormData(defaultFormData)
      setCurrentStep(EUploadStep.MAIN)
      setUploadingFiles([])
      setError(data)

      router.push('/error')
    }
  }

  const value: AppContextType = useMemo(
    () => ({
      isSubscriber,
      setIsSubscriber,
      subscription,
      setSubscription,
      error,
      setError: setGlobalError,
      password,
      setPassword,
      upload: {
        uploadingFiles,
        setUploadingFiles,
        formData,
        setFormData
      },
      transferData,
      setTransferData,
      currentStep,
      setCurrentStep
    }),
    [isSubscriber, subscription, error, uploadingFiles, formData, password, transferData, currentStep]
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default ContextProvider
