import RequestHelper, { IApiResponse } from '../../../RequestHelper'
import { IP_CHECK_URL } from '@/services/fetchers/backend/common'
import { ESubscription } from '@/shared/types'

interface ICheckSubscriberResponse {
  subscription: ESubscription
}

export const checkSubscriber = (): Promise<IApiResponse<ICheckSubscriberResponse | null>> =>
  RequestHelper.get<ICheckSubscriberResponse>({ url: IP_CHECK_URL })
