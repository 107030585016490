const commonTailwindConf = require('@transverse/digitalsystems-library/dist/tailwind.config')

const colors = {
  ...commonTailwindConf.theme.extend.colors,
  blue: {
    50: '#EFF6FF',
    100: '#0066CC',
    200: '#bfdbfe',
    300: '#59BDD5',
    500: '#3B82F6'
  },
  green: {
    50: '#F0FDF4',
    200: '#BBF7D0',
    500: '#009443'
  },
  info: {
    green: '#dceedc',
    red: '#fcd2d2',
    blue: '#EFF6FF'
  },
  repairability: {
    green: '#009443',
    'light-green': '#9ccc00',
    orange: '#fe761b',
    red: '#d91626',
    yellow: '#ffc801'
  }
}

module.exports = {
  mode: 'jit',
  content: [
    './node_modules/@transverse/digitalsystems-library/**/*.{js,ts,jsx,tsx}',
    './src/pages/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    ...commonTailwindConf.theme,
    extend: {
      ...commonTailwindConf.theme.extend,
      colors,
      dropShadow: {
        ...commonTailwindConf.theme.extend.dropShadow,
        'medium-top': '0 -16px 24px rgba(0, 0, 0, 0.04)',
        'medium-bottom': '0 24px 32px rgba(0, 0, 0, 0.04)'
      },
      fontFamily: {
        ...commonTailwindConf.theme.extend.fontFamily,
        free: ['Free-Regular']
      },
      lineHeight: {
        ...commonTailwindConf.theme.extend.lineHeight,
        '90%': '90%'
      },
      borderWidth: {
        3: '3px'
      },
      fontWeight: {
        ...commonTailwindConf.theme.extend.fontWeight,
        medium: 500
      },
      transitionProperty: {
        'max-height': 'max-height'
      },
      animation: {
        'fade-in': 'fade-in 100ms ease-in-out',
        'fade-in-up': 'fade-in-up 100ms ease-in-out',
        pulse: 'pulse 1s infinite ease-in-out'
      },
      keyframes: {
        'fade-in': {
          from: { transform: 'translate3d(0, -1rem, 0)', opacity: 0 },
          to: { transform: 'translate3d(0, 0, 0)', opacity: 1 }
        },
        'fade-in-up': {
          from: { transform: 'translate3d(0, 1rem, 0)', opacity: 0 },
          to: { transform: 'translate3d(0, 0, 0)', opacity: 1 }
        },
        pulse: {
          '0%': {
            opacity: 1
          },
          '50%': {
            opacity: 0.3
          },
          '100%': {
            opacity: 1
          }
        }
      }
    }
  },
  variants: {
    borderWidth: ['responsive', 'hover', 'focus', 'tablet-last'],
    textColor: ['responsive', 'dark', 'group-hover', 'focus-within', 'hover', 'focus', 'first', 'last', 'tablet-last']
  }
}
