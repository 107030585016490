import { configurationsService } from '@/shared/ConfigurationService'
import { EAppVersion } from '@/shared/types'

export const DIDOMI_API_KEY = '87df2f8d-232a-4617-8efc-3764b3bbd0c0'

export const BACK_URL = configurationsService.BACK_URL
export const PLANS_DATA_URL = 'https://mobile.free.fr/static/footer/plans-data.json'
export const BOOSTER_DATA_URL = 'https://mobile.free.fr/static/footer/booster-data.json'
export const HOME_FREE_MOBILE_URL = 'https://mobile.free.fr/'
export const HOME_FREEBOX_URL = 'https://free.fr/freebox/'

export const FILENAME_MAX_LENGTH = 100
export const MAX_EMAIL_TO_FIELD = 50

export const UPLOAD_TITLE = 'Envoi de fichiers'
export const DOWNLOAD_TITLE = 'Bienvenue sur Free transfert'

export const ARCHIVE_NAME = 'free-transfer.zip'

export const EMAIL_VALIDATION_REGEXP = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
export const VALIDATION_STRING = /^[a-zA-Z\u00C0-\u00FF]*$/
export const ONLY_STRING_ERROR = 'Seuls les caractères alphabétiques sont autorisés'
export const EMAIL_VALIDATION_ERROR = "Le format de l'email n'est pas valide"
export const EMAIL_REQUIRED_ERROR = 'Veuillez saisir une adresse email'

export const RECAPTCHA_PUBLIC_KEY = '6LduVqcbAAAAAKFKNbd2Kdhy05zGXX8ER4uG6TkG'

export const MULTIPART_FILE_SIZE = 100 * 1024 * 1024
export const GIGA_TO_OCTET = 1024 * 1024 * 1024
export const GIGA_TO_BYTES = 1000 * 1000 * 1000

export const STATUS_CODE = {
  SUCCESS: 200,
  UNAUTHORIZED: 403,
  NOT_FOUND: 404
}

export const LIMITS = {
  [EAppVersion.All]: {
    maxGlobalSize: 10,
    maxFileSize: 10,
    maxNbFile: 30,
    allowFoldersTransfer: false,
    validity: [1, 2, 7],
    maxRecipient: 10
  },
  [EAppVersion.SUBSCRIBER]: {
    maxGlobalSize: null,
    maxFileSize: null,
    maxNbFile: 200,
    allowFoldersTransfer: false,
    validity: [1, 2, 7, 14, 30],
    maxRecipient: 50
  }
}

export const SESSION_KEY = {
  FORM: 'form',
  TRANSFER: 'transfer'
}
