import { createContext } from 'react'
import { ESubscription, EUploadType, IError } from '@/shared/types'
import { DropzoneFile } from '@/components/templates/Upload/Steps/UploadItems'
import { TransferDataType } from '@/shared/types/transfer'
import { EUploadStep, UploadFormDataType } from '@/shared/types/upload'

export type AppContextType = {
  isSubscriber: boolean
  setIsSubscriber: (isSubscriber: boolean) => void
  subscription: ESubscription
  setSubscription: (subscription: ESubscription) => void
  error?: IError
  setError: (error?: IError, params?: Record<string, string>) => void
  password?: string
  setPassword: (password: string) => void
  upload: {
    uploadingFiles: DropzoneFile[]
    setUploadingFiles: (uploadingFiles: DropzoneFile[]) => void
    formData: UploadFormDataType
    setFormData: (formData: UploadFormDataType) => void
  }
  transferData?: TransferDataType
  setTransferData: (transferData: TransferDataType) => void
  currentStep: EUploadStep
  setCurrentStep: (currentStep: EUploadStep) => void
}

export const defaultFormData = {
  uploadType: EUploadType.link
} as UploadFormDataType

export const defaultAppContext: AppContextType = {
  isSubscriber: false,
  setIsSubscriber: () => {},
  subscription: ESubscription.NONE,
  setSubscription: () => {},
  error: undefined,
  setError: () => {},
  password: undefined,
  setPassword: () => {},
  transferData: undefined,
  setTransferData: () => {},
  currentStep: EUploadStep.MAIN,
  setCurrentStep: () => {},
  upload: {
    uploadingFiles: [],
    setUploadingFiles: () => {},
    formData: defaultFormData,
    setFormData: () => {}
  }
}

export const AppContext = createContext(defaultAppContext)
