import { init as initApm, apmBase } from '@elastic/apm-rum'
import { isSnapshot } from '@/utils/common'
import { BACK_URL } from '@/shared/constants'

export const initAPM = () => {
  // Add Elastic APM RUM monitoring (client side):
  if (!isSnapshot()) {
    initApm({
      serviceName: 'freetransfert-front',
      serverUrl: (window as any)._env_.APM_SERVER,
      environment: (window as any)._env_.GITLAB_ENVIRONMENT_NAME,
      distributedTracingOrigins: [new URL((window as any)._env_.BACK_URL).origin]
    })
  }
}

export const captureAPMError = (error: Error) => {
  apmBase.captureError(error)
}
