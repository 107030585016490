import { TAILWIND_BREAKPOINTS } from '@/utils/common'
import { useEffect, useState } from 'react'

export interface IBreakpoint {
  isMobile: boolean
  isTablet: boolean
  isSmallDesktop: boolean
  isMediumDesktop: boolean
  isLarge: boolean
  isMediumAndLargeDesktop: boolean
  isTabletAndMore: boolean
}

export const useBreakpoints = (): IBreakpoint => {
  const [width, setWidth] = useState(375)

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener('resize', () => setWidth(window.innerWidth))
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  }, [])

  return {
    isMobile: !!(width && width < TAILWIND_BREAKPOINTS.TABLET),
    isTablet: !!(width && TAILWIND_BREAKPOINTS.TABLET <= width && width < TAILWIND_BREAKPOINTS.SMALL_DESKTOP),
    isSmallDesktop: !!(width && width >= TAILWIND_BREAKPOINTS.SMALL_DESKTOP && width < TAILWIND_BREAKPOINTS.DESKTOP),
    isMediumDesktop: !!(width && width >= TAILWIND_BREAKPOINTS.DESKTOP && width < TAILWIND_BREAKPOINTS.LARGE),
    isLarge: !!(width && width >= TAILWIND_BREAKPOINTS.LARGE),
    isMediumAndLargeDesktop: !!(width && width >= TAILWIND_BREAKPOINTS.DESKTOP),
    isTabletAndMore: !!(width && width >= TAILWIND_BREAKPOINTS.TABLET)
  }
}
