import axios from 'axios'
import axiosRetry from 'axios-retry'

type RequestParams = { url: string; data?: any; config?: any; headers?: any; params?: any }

export const METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
}

interface IServiceParamsType {
  method: string
  url: string
  config?: object
  data?: object
  params?: object
  headers?: object
  responseType?: string
}

export interface IAxiosError {
  response?: {
    status: number
    data: {
      error: {
        message: string
        errorType: number
      }
    }
  }
}

export interface IResponseError {
  message: string
  errorCode?: number
}

export interface IApiResponse<T> {
  status: number
  data?: T
  error?: IResponseError
}

export const MAX_UPLOAD_RETRY = 10
export const MAX_RETRY_DELAY = 15000 // 15sc

axiosRetry(axios, {
  retries: MAX_UPLOAD_RETRY,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay
})

const parseAxiosResponse = <T>(response: IApiResponse<T>): IApiResponse<T | null> => {
  return {
    status: response.status,
    data: response.data || null
  }
}

const parseAxiosError = <T>(error: IAxiosError): IApiResponse<T | null> => {
  return {
    status: error.response ? error.response.status : 520, // unknown error code
    data: null,
    error: {
      message: error.response ? error.response.data.error?.message : 'Unknown error',
      errorCode: error.response ? error.response.data.error?.errorType : undefined
    }
  }
}

const axiosInstance = <T>(serviceParams: IServiceParamsType): Promise<IApiResponse<T | null>> => {
  const { method, url, data, config = {}, params = {}, headers = {}, responseType = '' } = serviceParams
  const instance = axios.create()
  instance.defaults.headers.common = {}

  const instanceParams: object = {
    method,
    url,
    data,
    ...config,
    params,
    responseType,
    headers: {
      ...headers,
      accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  return instance({ ...instanceParams })
    .then((response: IApiResponse<T>) => Promise.resolve(parseAxiosResponse<T>(response)))
    .catch((error: IAxiosError) => Promise.resolve(parseAxiosError<T>(error)))
}

export default class RequestHelper {
  static get<T>(params: RequestParams): Promise<IApiResponse<T | null>> {
    return axiosInstance<T>({ method: METHODS.GET, ...params })
  }

  static post<T>({ url, data, config, headers }: RequestParams): Promise<IApiResponse<T | null>> {
    return axiosInstance<T>({ method: METHODS.POST, url, data, config, headers })
  }

  static put<T>({ url, data, config, headers }: RequestParams): Promise<IApiResponse<T | null>> {
    return axiosInstance<T>({ method: METHODS.PUT, url, data, config, headers })
  }

  static patch<T>({ url, data, config, headers }: RequestParams): Promise<IApiResponse<T | null>> {
    return axiosInstance<T>({ method: METHODS.PATCH, url, data, config, headers })
  }

  static delete<T>({ url, data, config, headers }: RequestParams): Promise<IApiResponse<T | null>> {
    return axiosInstance<T>({ method: METHODS.DELETE, url, data, config, headers })
  }
}
