import React from 'react'
import { isSubscriberType } from '@/pages'
import clsx from 'clsx'

type HeaderType = isSubscriberType & {
  onClick: () => void
  displayBackground?: boolean
}

const Header = ({ displayBackground = true, onClick, isSubscriber }: HeaderType) => {
  return (
    <div
      className={clsx(
        'w-full flex flex-col justify-center items-center py-6 laptop:pt-14',
        displayBackground && 'bg-gray-50'
      )}
    >
      <img
        src={isSubscriber ? '/assets/images/logo_free_transfert_premium.svg' : '/assets/images/logo_free_transfert.svg'}
        className={clsx('w-fit', isSubscriber ? 'h-12 laptop:h-14' : 'h-6 laptop:h-8')}
        alt="logo Free Transfert"
        onClick={onClick}
        role="button"
        onKeyDown={onClick}
        tabIndex={0}
      />
    </div>
  )
}

export default Header
