import tailwindConfig from '../../tailwind.config'

/**
 * @param input For instance `768px`.
 */
const parseTailwindBreakpointConfig = (input: string): number => Number(input?.slice(0, -2))

export const TAILWIND_BREAKPOINTS = {
  TABLET: parseTailwindBreakpointConfig(tailwindConfig.theme.screens.tablet),
  SMALL_DESKTOP: parseTailwindBreakpointConfig(tailwindConfig.theme.laptop),
  DESKTOP: parseTailwindBreakpointConfig(tailwindConfig.theme.screens.desktop),
  LARGE: parseTailwindBreakpointConfig(tailwindConfig.theme.screens['desktop-lg'])
}

export const isSnapshot = () => typeof window === 'undefined'

export const isEmpty = (obj) => {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}
