import React, { useContext, useEffect, useMemo } from 'react'
import { AppContext } from '@/shared/contexts/AppContext'
import clsx from 'clsx'
import { EUploadStep } from '@/shared/types/upload'
import { useRouter } from 'next/router'
import useAppVersion from '@/hooks/useAppVersion'
import Header from '@/components/templates/Shared/Header/Header'

const { AnimatePresence } = require('framer-motion')

interface ILayout {
  children: any
}

const Layout = ({ children }: ILayout) => {
  const { setError, currentStep, isSubscriber } = useContext(AppContext)

  const router = useRouter()

  useAppVersion()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const displayBackground = useMemo(
    () =>
      router.pathname !== '/' &&
      router.pathname !== '/comparator' &&
      (router.pathname !== '/upload' || currentStep !== EUploadStep.MAIN),
    [currentStep, router.pathname]
  )

  const onClick = () => {
    window.location.replace(window.location.origin)
  }

  return (
    <div className={clsx(displayBackground && 'tablet:bg-gray-50')}>
      <div className="flex flex-col items-center justify-center w-full min-screen-height">
        <Header isSubscriber={isSubscriber} onClick={onClick} displayBackground={displayBackground} />
        <AnimatePresence>{children}</AnimatePresence>
      </div>

      <div className="p-4 text-16 w-full text-center">
        <a
          target="_blank"
          href="/data/CGU_FREE_TRANSFERT_080223.pdf"
          className="font-semibold text-status-info underline"
        >
          Consulter les Conditions Générales d'Utilisation du service Free Transfert
        </a>
        <p className="text-center text-12 mt-2">Dernière mise à jour : 08/02/2023</p>
      </div>
    </div>
  )
}

export default Layout
