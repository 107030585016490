import { isSnapshot } from '@/utils/common'

export interface ITracking {
  category: string
  action: string
  name: string
  value?: number
}

export const TRACKING_CATEGORY = {
  uploadPage: 'Upload Page'
}

export const trackPageView = () => {
  if (!isSnapshot() && document && (window as any)._paq) {
    ;(window as any)._paq.push(['setCustomUrl', (document as any).URL])
    ;(window as any)._paq.push(['setDocumentTitle', (document as any).title])
    ;(window as any)._paq.push(['trackPageView'])
  }
}

export const triggerEvent = ({ category, action, name, value }: ITracking) => {
  if (!isSnapshot() && (window as any)._paq) {
    ;(window as any)._paq.push(['trackEvent', category, action, name, value])
  }
}
