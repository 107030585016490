import { EUploadType } from '@/shared/types'

export enum EUploadStep {
  MAIN,
  LIST,
  TYPE,
  VALIDITY,
  RECIPIENT,
  MESSAGE,
  PASSWORD,
  UPLOADING,
  CONFIRMATION,
  DELETE_CONFIRMATION
}

export type StepType = {
  stepNumber: number | null
  title: string | null
  progression: number | null
  displayDiscoverCard?: boolean
}

export const STEPS_WITH_LINK = [
  EUploadStep.MAIN,
  EUploadStep.LIST,
  EUploadStep.TYPE,
  EUploadStep.VALIDITY,
  EUploadStep.PASSWORD,
  EUploadStep.UPLOADING,
  EUploadStep.CONFIRMATION
]

export const STEPS_WITH_EMAIL = [
  EUploadStep.MAIN,
  EUploadStep.LIST,
  EUploadStep.TYPE,
  EUploadStep.VALIDITY,
  EUploadStep.RECIPIENT,
  EUploadStep.MESSAGE,
  EUploadStep.PASSWORD,
  EUploadStep.UPLOADING,
  EUploadStep.CONFIRMATION
]

export const STEPS_TITLE = ({ maxRecipient }: { maxRecipient: number }) => ({
  [EUploadStep.LIST]: 'Mes chargements',
  [EUploadStep.TYPE]: 'Choisissez votre type d’envoi',
  [EUploadStep.VALIDITY]: 'Choisissez la durée de validité du transfert',
  [EUploadStep.RECIPIENT]: `Ajoutez jusqu’à ${maxRecipient} destinataires`,
  [EUploadStep.MESSAGE]: 'Saisissez votre email et votre message',
  [EUploadStep.PASSWORD]: 'Protégez votre transfert'
})

export const STEPS_WITHOUT_PROGRESSION = [
  EUploadStep.UPLOADING,
  EUploadStep.CONFIRMATION,
  EUploadStep.DELETE_CONFIRMATION
]

export const defaultStep = {
  stepNumber: null,
  title: null,
  progression: null
}

export interface IFormFile {
  path: string
  size: number
  mimetype: string
}

export type UploadFormDataType = {
  uploadType: EUploadType
  validity: string
  availability: string
  sender: string
  message?: string
  password: string
  recipients: string[]
  files: IFormFile[]
}

export const FORM_KEY = {
  uploadType: 'uploadType',
  files: 'files',
  recipients: 'recipients',
  sender: 'sender',
  message: 'message',
  password: 'password',
  validity: 'validity',
  optIn: 'optIn'
}
