export const helmet = {
  title: "Free Transfert - Service d'envoi de fichiers",
  canonicalLink: 'https://transfert.free.fr/',
  metas: [
    {
      name: 'description',
      content:
        "Service d'envoi et de partage de fichiers, simple, gratuit et sécurisé destiné aussi bien aux particuliers qu'aux entreprises."
    },
    {
      name: 'title',
      content: 'Free Transfert'
    },
    {
      name: 'site_name',
      content: 'Free Transfert'
    },
    {
      property: 'og:title',
      content: 'Free Transfert'
    },
    {
      property: 'og:description',
      content:
        "Service d'envoi et de partage de fichiers, simple, gratuit et sécurisé destiné aussi bien aux particuliers qu'aux entreprises."
    },
    {
      property: 'og:url',
      content: `//transfert.free/`
    },
    {
      property: 'og:site_name',
      content: 'Free Transfert'
    },
    {
      property: 'og:image',
      content: '//transfert.free.fr/assets/images/Logo_FreeTransfert_diag.png'
    },
    {
      property: 'og:image:type',
      content: 'image/png'
    },
    {
      property: 'og:image:width',
      content: '1622'
    },
    {
      property: 'og:image:height',
      content: '500'
    },
    {
      property: 'twitter:site',
      content: 'Free Transfert'
    },
    {
      property: 'twitter:creator',
      content: 'Free'
    },
    {
      property: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      property: 'twitter:title',
      content: 'Free Transfert'
    },
    {
      property: 'twitter:description',
      content:
        "Service d'envoi et de partage de fichiers, simple, gratuit et sécurisé destiné aussi bien aux particuliers qu'aux entreprises."
    },
    {
      property: 'twitter:image:src',
      content: '//transfert.free.fr/assets/images/Logo_FreeTransfert_diag.png'
    },
    {
      property: 'twitter:image:width',
      content: '1622'
    },
    {
      property: 'twitter:image:height',
      content: '500'
    }
  ]
}
