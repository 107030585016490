import { useContext, useEffect } from 'react'
import { AppContext } from '@/shared/contexts/AppContext'
import { checkSubscriber } from '@/services/fetchers/backend/users/checkSubscriber'
import { ESubscription } from '@/shared/types'
import { configurationsService } from '@/shared/ConfigurationService'

const useAppVersion = () => {
  const { setSubscription, setIsSubscriber, setError } = useContext(AppContext)

  useEffect(() => {
    if (!configurationsService.FORCE_NON_SUBSCRIBER) {
      checkSubscriber()
        .then((response) => {
          const subscription = response.data?.subscription ?? ESubscription.NONE

          setSubscription(subscription)
          setIsSubscriber(subscription !== ESubscription.NONE)
        })

        .catch(() => {
          setError({ name: 'Check subscriber' })
        })
    }
  }, [])
}

export default useAppVersion
