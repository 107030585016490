import { BACK_URL } from '@/shared/constants'

export const IP_CHECK_URL = `${BACK_URL}/users/subscription`
export const STATS_URL = `${BACK_URL}/transfers/stats`
export const CREATE_URL = `${BACK_URL}/transfers`
export const FETCH_CHUNK_URL = (key: string) => `${BACK_URL}/transfers/${key}/chunk`
export const VALIDATE_CHUNK_URL = (key: string) => `${BACK_URL}/transfers/${key}/chunk`
export const CREATE_REPORT_URL = `${BACK_URL}/reports`
export const VALIDATE_REPORT_URL = `${BACK_URL}/reports/validate`
export const FETCH_URL = (key: string) => `${BACK_URL}/transfers/${key}`
export const DELETE_URL = `${BACK_URL}/transfers`
export const DOWNLOAD_URL = `${BACK_URL}/files`
export const FETCH_REPORT_CHUNK_URL = (key: string) => `${BACK_URL}/reports/${key}/chunk`
export const VALIDATE_REPORT_CHUNK_URL = (key: string) => `${BACK_URL}/reports/${key}/chunk`
